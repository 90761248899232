import React, { useContext } from "react"
import Loading from "../components/loading"

export const LoadingContext = React.createContext(null)

export function LoadingContextProvider(parametros) {
    const { children } = parametros
    const [loading, setLoading] = React.useState(false)

    return (
        <LoadingContext.Provider
            value={{
                loading,
                setLoading,
            }}
        >
            <Loading />
            {children}
        </LoadingContext.Provider>
    )
}

export default function useLoading() {
    const context = useContext(LoadingContext)

    if (!context) throw new Error("useEndereco must be within a Context Provider")

    return context.setLoading
}

import { createContext, useContext, useEffect, useState } from "react"
import { Post_ListarEstabelecimentosPorEndereco, RegistrarLogErro } from "../services/functions"
import { isObjectEmpty } from "../services/utils"
import useAlert from "./AlertContext"
import { CalculosContext } from "./CalculosContext"
import useLoading from "./LoadingContext"
import useParametros from "./ParametrosContext"
import SiteContext from "./SiteContext"

const EnderecoContext = createContext(null)

export const EnderecoContextProvider = ({ children }) => {
    // TODO: Substituir propriedades de carrinho por cardápio
    const { aplicativoDados, estabelecimentoAtual, usuarioLogado, carrinho, cardapio } =
        useContext(SiteContext)

    const parametros = useParametros()
    const alertStart = useAlert()
    const setLoading = useLoading()

    const [enderecoAtual, setEnderecoAtual] = useState(
        JSON.parse(sessionStorage.getItem("enderecoAtual") ?? "{}")
    )

    const taxaEntrega = enderecoAtual ? enderecoAtual.taxaEntrega : null
    const entregaNoEndereco = taxaEntrega !== -1 // TODO: Checar se funciona com retirada e mesa

    const [novoEndereco, setNovoEndereco] = useState({})

    const [estabelecimentosQueEntregam, setEstabelecimentosQueEntregam] = useState([])

    const [abrirEnderecos, setAbrirEnderecos] = useState(false)
    const [abrirNovoEndereco, setAbrirNovoEndereco] = useState(false)
    const [abrirCEP, setAbrirCEP] = useState(false)
    const [abrirGoogleMaps, setAbrirGoogleMaps] = useState(false)
    const [abrirMapBox, setAbrirMapBox] = useState(false)

    const { calcularValorTotal } = useContext(CalculosContext)

    useEffect(() => {
        if (enderecoAtual) selecionarEndereco(enderecoAtual)
    }, [])

    // TODO: Remover
    if (enderecoAtual?.id === "retirada") throw new Error('Endereço possui id igual a "retirada"')

    const valorTotalCarrinho = carrinho?.pedido?.valorTotal ?? 0

    const possuiEndereco = !!(enderecoAtual?.id || enderecoAtual?.logradouro)
    const isDelivery = !!enderecoAtual?.logradouro
    const isRetiradaNoLocal = enderecoAtual?.id === -1
    const permiteRetiradaNoLocal =
        estabelecimentoAtual?.permiteRetiradaBalcao || aplicativoDados?.permiteRetiradaBalcao

    const minimoRetiradaNoLocal = cardapio?.valorMinimoRetiradaNoLocal ?? 0
    const minimoEntregaGratis =
        carrinho.minimoEntregaGratis > 0 && carrinho.minimoEntregaGratis < 999
            ? carrinho.minimoEntregaGratis
            : null

    const isMinimoRetiradaNoLocal = isRetiradaNoLocal && valorTotalCarrinho >= minimoRetiradaNoLocal
    const isFreteGratisMinimoEntrega =
        minimoEntregaGratis && valorTotalCarrinho >= minimoEntregaGratis

    const selecionarEndereco = async endereco => {
        try {
            if (!endereco || isObjectEmpty(endereco)) return

            setLoading(true)

            endereco.token = `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`
            endereco.appNome = aplicativoDados.appNome

            const retorno = await Post_ListarEstabelecimentosPorEndereco(endereco, aplicativoDados)

            if (retorno.retornoErro) {
                alertStart(retorno.mensagem, "error")
                RegistrarLogErro(retorno.mensagem, "selecionarEndereco (listar)", {
                    endereco,
                    retorno,
                })
                setLoading(false)
                return
            }

            setEstabelecimentosQueEntregam(retorno.filter(e => e.taxaEntrega !== -1))

            if (!estabelecimentoAtual?.id) {
                setEnderecoAtual(endereco)
                setLoading(false)
                return
            }

            const taxaEntrega = retorno.find(
                estab => estab.id === estabelecimentoAtual.id
            )?.taxaEntrega

            if (taxaEntrega === undefined || taxaEntrega === null) {
                alertStart("Desculpe, houve um erro com o seu endereço.", "error")
                RegistrarLogErro("Taxa de entrega nula", "selecionarEndereco (taxa)", {
                    endereco,
                    retorno,
                })
                setLoading(false)
                return
            }

            const enderecoComTaxa = {
                ...endereco,
                taxaEntrega,
            }

            setEnderecoAtual(enderecoComTaxa)

            calcularValorTotal({ enderecoAtual: enderecoComTaxa })

            sessionStorage.setItem("enderecoAtual", JSON.stringify(enderecoComTaxa))

            setLoading(false)
        } catch (error) {
            RegistrarLogErro(error, "selecionarEndereco", { endereco })
        }
    }

    const selecionarRetiradaNoLocal = () => {
        const endereco = { id: -1 }

        setEnderecoAtual(endereco)
        finalizarCadastroEndereco()

        calcularValorTotal({ enderecoAtual: endereco })

        sessionStorage.setItem("enderecoAtual", JSON.stringify(endereco))
    }

    const abrirListaEnderecos = () => setAbrirEnderecos(true)
    const fecharListaEnderecos = () => setAbrirEnderecos(false)

    const abrirCadastroEndereco = () => setAbrirNovoEndereco(true)
    const fecharCadastroEndereco = () => setAbrirNovoEndereco(false)

    const abrirCadastroCEP = () => setAbrirCEP(true)
    const fecharCadastroCEP = () => setAbrirCEP(false)

    const abrirCadastroGoogleMaps = () => setAbrirGoogleMaps(true)
    const fecharCadastroGoogleMaps = () => {
        setAbrirGoogleMaps(false)

        // TODO: Entender por quê ao fechar Maps no Açaí do Kim o scroll não retorna
        document.body.style.overflow = "auto"
    }

    const abrirCadastroMapBox = () => setAbrirMapBox(true)
    const fecharCadastroMapBox = () => setAbrirMapBox(false)

    const cadastrarNovoEndereco = () => {
        switch (aplicativoDados.tipoSelecaoEndereco) {
            case 0:
                const isBairroEspecifico = aplicativoDados.tipoEntregaBairro === 1
                if (isBairroEspecifico || parametros.PUBLIC_DESABILITAR_CADASTRO_CEP === true)
                    abrirCadastroEndereco()
                else abrirCadastroCEP()
                break
            case 1:
                abrirCadastroGoogleMaps()
                break
            case 2:
                abrirCadastroMapBox()
                break
            default:
                throw new Error("tipoSelecaoEndereco not handled")
        }
    }

    const iniciarCadastroEndereco = () => {
        if (usuarioLogado?.logado) {
            abrirListaEnderecos()
            return
        }

        cadastrarNovoEndereco()
    }

    const finalizarCadastroEndereco = () => {
        fecharListaEnderecos()
        fecharCadastroEndereco()
        fecharCadastroCEP()
        fecharCadastroGoogleMaps()
        fecharCadastroMapBox()
    }

    const value = {
        enderecoAtual,
        selecionarEndereco,
        novoEndereco,
        setNovoEndereco,

        abrirEnderecos,
        abrirNovoEndereco,
        abrirCEP,
        abrirGoogleMaps,
        abrirMapBox,

        abrirListaEnderecos,
        fecharListaEnderecos,
        abrirCadastroEndereco,
        fecharCadastroEndereco,
        abrirCadastroCEP,
        fecharCadastroCEP,
        abrirCadastroGoogleMaps,
        fecharCadastroGoogleMaps,
        abrirCadastroMapBox,
        fecharCadastroMapBox,

        possuiEndereco,
        entregaNoEndereco,
        isDelivery,
        isRetiradaNoLocal,
        permiteRetiradaNoLocal,
        taxaEntrega,
        minimoRetiradaNoLocal,
        minimoEntregaGratis,
        isMinimoRetiradaNoLocal,
        isFreteGratisMinimoEntrega,
        estabelecimentosQueEntregam,

        cadastrarNovoEndereco,
        iniciarCadastroEndereco,
        finalizarCadastroEndereco,
        selecionarRetiradaNoLocal,
    }

    return <EnderecoContext.Provider value={value}>{children}</EnderecoContext.Provider>
}

export default function useEndereco() {
    const context = useContext(EnderecoContext)

    if (!context) throw new Error("useEndereco must be within a Context Provider")

    return context
}

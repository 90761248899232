import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core"
import { ArrowForwardIos as ArrowForwardIosIcon } from "@material-ui/icons"
import { useEffect, useState } from "react"

const useStyles = makeStyles(theme => ({
    envioCodigo: {
        flexDirection: "column",
        gap: "8px",
    },
    envioCodigo_botao: {
        color: "white",
        backgroundColor: "#28a745",
        margin: "4px",
        "&:hover": {
            backgroundColor: "#28a745",
        },
    },
    envioCodigo_sms: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "4px",
        color: "black",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    envioCodigo_textoCentral: {
        marginTop: "24px",
        marginBottom: "12px",
        color: "black",
        [theme.breakpoints.down("xs")]: {
            marginTop: "16px",
            marginBottom: "16px",
        },
    },
    input: {
        letterSpacing: "5px",
        width: "112px",
        fontSize: "24px",
        caretColor: "#999",
        "&::placeholder": { textAlign: "center" },
    },
}))

const useCooldown = () => {
    const [tempoReenvio, setTempoReenvio] = useState(30)
    const [tentativas, setTentativas] = useState(0)

    useEffect(() => {
        const countdown = setInterval(
            () => setTempoReenvio(tempoReenvio => Math.max(tempoReenvio - 1, 0)),
            1000
        )
        return () => clearInterval(countdown)
    }, [])

    const calcularTempoReenvio = () => {
        setTentativas(tentativas => tentativas + 1)
        switch (tentativas) {
            case 0:
                setTempoReenvio(45)
                break
            case 1:
                setTempoReenvio(60)
                break
            default:
                setTempoReenvio(120)
                break
        }
    }

    return [tempoReenvio, calcularTempoReenvio]
}

const EnviarNovamenteButton = ({ enviarNovamente }) => {
    const [tempoReenvio, calcularTempoReenvio] = useCooldown()

    const tempoFormatado = new Date(tempoReenvio * 1000).toISOString().substring(14, 19)

    const classes = useStyles()

    const handleClick = () => {
        calcularTempoReenvio()
        enviarNovamente()
    }

    return (
        <Button
            className={tempoReenvio === 0 && classes.envioCodigo_botao}
            disabled={!!tempoReenvio}
            variant="contained"
            fullWidth
            onClick={handleClick}
        >
            Enviar Novamente
            {!!tempoReenvio && " em " + tempoFormatado}
        </Button>
    )
}

const EnviarSMSButton = ({ enviarPorSMS }) => {
    const [tempoReenvio, calcularTempoReenvio] = useCooldown()

    const classes = useStyles()

    const handleClick = () => {
        calcularTempoReenvio()
        enviarPorSMS()
    }

    return (
        <Button
            disabled={!!tempoReenvio}
            className={classes.envioCodigo_botao}
            variant="contained"
            onClick={handleClick}
        >
            Enviar por SMS
        </Button>
    )
}

export default function DialogCadastroPorMensagem({
    loginUsuarioSenha,
    abrirDialogCadastroIzza,
    setAbrirDialogCadastroIzza,
    enviarPorWpp,
    enviarPorSMS,
    senha,
    setSenha,
}) {
    const classes = useStyles()

    useEffect(() => {
        if (abrirDialogCadastroIzza) enviarPorWpp()
    }, [abrirDialogCadastroIzza])

    useEffect(() => {
        if (abrirDialogCadastroIzza && senha.length === 6) loginUsuarioSenha()
    }, [senha])

    function handleClose() {
        setAbrirDialogCadastroIzza(false)
    }

    return (
        <Dialog open={abrirDialogCadastroIzza} maxWidth="false" onClose={handleClose}>
            <DialogTitle>
                <IconButton className="setaVoltarModal" onClick={handleClose}>
                    <ArrowForwardIosIcon />
                </IconButton>
                Autenticação
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ lineHeight: "24px", margin: 0 }}>
                    <Typography className={classes.envioCodigo_textoCentral}>
                        Informe o código recebido em seu WhatsApp para continuar.
                    </Typography>
                    <Typography className={classes.envioCodigo_sms}>
                        Se preferir, podemos também enviar o código por SMS.
                        <EnviarSMSButton enviarPorSMS={enviarPorSMS} />
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.envioCodigo}>
                <TextField
                    InputProps={{
                        classes: {
                            input: classes.input,
                        },
                    }}
                    margin="normal"
                    onChange={e => e.target.value.length <= 6 && setSenha(e.target.value)}
                    value={senha}
                    placeholder="000000"
                    autoFocus
                />
                <Button
                    className={classes.envioCodigo_botao}
                    variant="contained"
                    fullWidth
                    onClick={loginUsuarioSenha}
                >
                    Continuar
                </Button>
                <EnviarNovamenteButton enviarNovamente={enviarPorWpp} classes={classes} />
            </DialogActions>
        </Dialog>
    )
}
